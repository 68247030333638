<!-- <div class="scaffold">
    <div style="height: 100%; width: 100%; position: relative">
      <div
        style="
          height: 70%;
          width: 100%;

          background:
            url('/assets/images/client-image-auth.png');
          position: absolute;
          object-fit: cover;
          background-repeat: no-repeat;
          background-size: cover;
        "
      ></div>

      <div
        style="
          height: 100%;
          width: 100%;
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          style="
            width: 90%;
            max-width: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            gap: 100px;
          "
        >
        <img
        src="/assets/logos/atul.png"
        width="400px"
        style=" border-radius: 5px"
      />
      <img  src="/assets/logos/powered_by.gif" alt="Powered By GIF" width="300px"
      style=" border-radius: 5px; z-index: 1; position: absolute;
      margin-top: 160px; align-items:center; justify-content:center; margin-left:0px; ">


          <form class="form" (ngSubmit)="handleLogin()" style="height: 310px">
            <div
              style="
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;
                justify-content: center;
                align-items: center;
              "
            >
              <mat-form-field style="width: 100%" appearance="outline">
                <mat-label>Username</mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="usernameFormControl"
                  [errorStateMatcher]="matcher"
                  [(ngModel)]="email"
                  placeholder="Username"
                  style="font-size: 14px"
                />


                <mat-error *ngIf="usernameFormControl.hasError('required')">
                  Username is <strong>required</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field style="width: 100%" appearance="outline">
                <mat-label>Password</mat-label>
                <input
                  type="password"
                  matInput
                  [formControl]="passwordFormControl"
                  [errorStateMatcher]="matcher"
                  [(ngModel)]="password"
                  placeholder="Password"
                  style="font-size: 14px"
                />

                <mat-error *ngIf="passwordFormControl.hasError('required')">
                  Password is <strong>required</strong>
                </mat-error>
              </mat-form-field>

              <button
                mat-raised-button
                style="
                  width: 100%;
                  background-color: #505549;
                  height: 45px;
                  color: white;
                  font-family: sans-serif;
                "
                type="submit"
              >
                Login
              </button>

              <a
                class="hover-text-dark"
                [routerLink]="['/forgot-password']"
                style="margin-top: 5px; margin-bottom: 0px"
                >Forgot Password?</a
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  -->

  <div class="scaffold">
    <div style="height: 100%; width: 100%; position: relative">
      <div
        style="
          height: 80%;
          width: 100%;
          background:
            url('/assets/images/client-image-auth.png');
          position: absolute;
          object-fit: cover;
          background-repeat: no-repeat;
          background-size: cover;
        "
      ></div>

      <div
        style="
          height: 100%;
          width: 100%;
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          style="
            /* width: 90%; */
            /* max-width: 500px; */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            /* gap: 140px; */
          "
          class="client-logo"
        >


  <div style="width: 500px;background: #ffffff; border-radius: 20px ;height: 23vh; display: flex; justify-content: center; align-items: center;">
    <!-- <div style="align-items: center; "> -->
      <!-- <img src="/assets/logos/atul.png" style=" width:350px ;height: auto; object-fit: cover;" /> -->
      <img src="/assets/logos/atul-logo-login.svg" width="350px" style="border-radius: 5px;" />
    <!-- </div> -->
    <div style="position: absolute; top: 78%; left: 50%; transform: translate(-50%, -50%);">
      <img src="/assets/logos/powered_by.gif" width="280px" />
    </div>
  </div>


          <form (ngSubmit)="handleLogin()"  class="form">
            <!-- <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>Username</mat-label> -->
            <input type="text"
            matInput
            [formControl]="usernameFormControl"
            [errorStateMatcher]="matcher"
            [(ngModel)]="email"
            placeholder="Username" class="form-item">
            <!-- <mat-error *ngIf="usernameFormControl.hasError('required')">
              Username is <strong>required</strong>
            </mat-error>
          </mat-form-field> -->
          <!-- <mat-form-field style="width: 100%" appearance="outline">
            <mat-label>Password</mat-label> -->
            <input  type="password"
            matInput
            [formControl]="passwordFormControl"
            [errorStateMatcher]="matcher"
            [(ngModel)]="password"
            placeholder="Password" class="form-item" />
            <!-- <mat-error *ngIf="passwordFormControl.hasError('required')">
              Password is <strong>required</strong>
            </mat-error>
          </mat-form-field> -->

            <button mat-raised-button class="btn"  type="submit"style="
            color: white;
            font-family: sans-serif;
          ">Login</button>
            <!-- <a
                class="hover-text-dark"
                [routerLink]="['/forgot-password']"
                style="margin-top: 15px; color:black; margin-bottom: 0px"
                >Forgot Password?</a
              > -->
        </form>



        </div>
      </div>
    </div>
  </div>
