<div class="scaffold">
    <div class="background-image"></div>

    <div class="animation-container">
      <!-- <div class="animation-element"> -->
        <!-- <img class="uniliver-logo" src="/assets/logos/atul.png"  alt="Generic Hospital Logo"> -->
    <!-- <div style="display: flex;align-items: center;justify-content: center; text-align: center;font-size: 36px;font-weight: 600;color: #4D5B46 ;width: 350px;;height: auto;">Chlor Alkali</div> -->

        <!-- <img class="uniliver-logo-powered-by" src="/assets/logos/powered_by.gif" alt="Powered By GIF" width="300px"
          style=" border-radius: 5px; z-index: 1; position: absolute;
          margin-top: 140px; margin-left:0px; ">
      </div> -->

      <div style="width: 500px;background: #ffffff; border-radius: 20px ;height: 23vh; display: flex; justify-content: center; align-items: center;">
        <!-- <div style="align-items: center; "> -->
          <!-- <img src="/assets/logos/atul.png" style=" width:350px ;height: auto; object-fit: cover;" /> -->
          <img class="logo" src="/assets/logos/client-logo.png"  alt="Logo" width="200px">
        <!-- </div> -->
        <div style="position: absolute; top: 58%; left: 50%; transform: translate(-50%, -50%);">
          <img src="/assets/logos/powered_by.gif" width="280px" />
        </div>
      </div>

      <!-- <div class="animation-element">
        <span class="span-text-light powered-by">Powered by:</span>
      </div> -->

      <div class="animation-element">
        <!-- <div class="bert-labs-logo">
          <img src="/assets/logos/bertlabs-logo.png" alt="Bert Labs Logo" width="100%">
        </div> -->
      </div>
    </div>
  </div>
