<div style="
    height: 65px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.06),
      0px 0px 2px rgba(25, 25, 25, 0.12);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  ">

  <!-- logos -->
  <div style="background: #ffffff; display: flex;flex-direction: row;align-items: center;padding: 5px;gap: 5px;">
    <!-- <img src="/assets/logos/atul-logo-topbar.svg" height="65px"> -->
    <img src="/assets/logos/client-logo-bg.png" height="50px">

    <!-- <div style="height: 100%; width: 1px;background-color: #000;"></div>

    <img src="/assets/logos/bertlabs-logo.png" height="30px"> -->
  </div>

  <!-- title -->
  <span class="title-dark" style="font-size: 20px;color:#505549">{{title}}</span>

  <!-- account -->

  <div class="account-section" (click)="showLogoutDialog()">
    <!-- <span class="span-text-dark" style="font-weight: 500;">User</span> -->

    <img src="/assets/images/SignOut.png" style="height: 40px; border-radius: 20px;">

  </div>

</div>
